.text-editor textarea {
    resize: none;
    overflow: hidden;
    outline: none;
    color: #646464;
    background-color: white;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 1px;
    padding: 0; /* padding má nastaven až input-box, protože ve Firefoxu 87.0 se nějak špatně měřila velikost pomocí getComputedStyle (v TextEditor.js) a editoru chyběl spodní okraj */
    border: none;
    flex-grow: 1;
}


.text-editor .input-box {
    padding: 12px;
}