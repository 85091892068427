.node-bubble {
    position: absolute;
    left: calc(50% + -32px);
    top: calc(100% + 8px);
    width: 180px;
    border-radius: 4px;
    padding: 4px 12px;
    background-color: #00b0e6;
    color: white;
    line-height: 150%;
    font-weight: 500;
    box-shadow: 0px 0px 14px 0px rgba(50, 50, 50, 0.3);
    z-index: 1000;
}

#narrow .node-bubble {
    font-size: 12px;
    width: 80px;
}

.node-bubble::after {
    content: " ";
    position: absolute;
    left: 24px;
    top: -8px;
    border-top: none;
    border-right:  8px solid transparent;
    border-left:   8px solid transparent;
    border-bottom: 8px solid #00b0e6;
}

.node-bubble.clickable {
    cursor: pointer;
}

.node-bubble.right {
    left: unset;
    right: calc(50% + -32px);
    text-align: right;
}

.node-bubble.right::after {
    left: unset;
    right: 24px;
}
