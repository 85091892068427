.login-box {
/*    padding-top: 200px;*/
    max-width: 400px;
}

.login-box .msg-box {
    min-height: 90px;
    display: flex;
    flex-direction: column-reverse;
}

#login2 .login-box .msg-box {
    min-height: 0px;
}

.login-box .msg {
    color: black;
    font-style: italic;
    margin-bottom: 6px;
}

.login-box .wait-indicator {
    margin-bottom: 8px;
}

.login-box div.error-frame {
    border: 2px solid #ff3c00;
}

.login-box .input-box {
    margin-bottom: 12px;
    width: 100%;
}

#narrow .login-box .input-box {
    margin-bottom: 20px;
}


.login-box .pwd-visible {
    width: 24px;
    vertical-align: center;
    padding: 0 12px;
}

.login-box button {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 12px 12px;
    border: 2px solid black;
    margin-bottom: 12px;
    color: #646464;
}

#narrow .login-box button {
    margin-bottom: 20px;
}

.login-box .btn-row {
    display: flex;
    flex-direction: row;
}

.login-box .btn1:hover {
    background-color: #7F1E00;
}

.login-box .btn2:hover {
    background-color: #FF9D7F;
}

.login-box .btn1 {
    flex-grow: 1;
    box-sizing: border-box;
    background-color: black;
    color: white;
}

.login-box button:first-child {
    margin-right: 24px;
}

.login-box .btn2 {
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    color: black;
}

.login-box #facebook {
    padding: 12px 0 12px 32px;
    background: url("./graphics/facebook-icon-bw.svg") no-repeat left center/20px 20px;
}

.login-box #google {
    padding: 12px 0 12px 32px;
    background: url("./graphics/google-icon-bw.svg") no-repeat left center/20px 20px;
}

.login-box p {
    text-align: center;
    line-height: 120%;
    font-size: 15px;
    margin: 0;
    position: relative;
    top: -4px;
}
