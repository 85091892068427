.unread-messages {
    margin-top: 24px;
}

/* (Formátování zpráv je společné s MessagePane; pod classNane = "message-group")*/
.unread-messages .msgs {
    cursor: pointer;
}

.unread-messages .message-group .title {
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: black;
}
.unread-messages .message-group .time {
    font-size: 12px;
    line-height: 100%;
    margin-bottom: 8px;
    text-transform: capitalize; /* Aby se "dnes" a "včera" převedlo první velké písmenu */
}


