.match-control {
	margin: 0 0 36px 8px;
}

#narrow .match-control {
	margin-left: 0;
}

.match-control .toggle-row {
	display: flex;
	flex-direction: row;
	line-height: 100%;
	font-weight: 500;
	align-items: center;
}

.match-control .toggle-button {
	margin-right: 10px;
}

.match-control .indent {
	margin-left: 36px;
}

.match-control .error {
    margin: 16px 24px 6px 0;
	line-height: 150%;
}

.match-control .error.top {
    margin-bottom: 16px;
}

.match-control .msg {
    margin: 8px 24px 16px 0;
	font-size: 14px;
	line-height: 180%;
}

.match-control .msg2 {
    margin: 16px 24px 6px 0;
	font-size: 14px;
	line-height: 180%;
	font-weight: 500;
	color: black;
}

.match-control .wait-indicator {
	font-size: 14px;
}