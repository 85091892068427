.sub-header {
    position: relative;
    display: flex;
    flex: 0 0;
    align-items: center;
    padding: 20px 8px 20px 32px;
    border-bottom: 1px solid #EEE;
    background: linear-gradient(to right, white 95%, #fafafa);
}

#narrow .sub-header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    background: white;
    box-shadow: 0px 0px 39px 0px rgba(0,0,0,0.08);
    z-index: 1; /* aby byl st�n nad obsahem panelu, ale pod st�nem hlavn�ho headeru*/
}

.sub-header.sub-present {
    padding-bottom: 15px;
}

.sub-header .arrow {
    width: 20px;
    height: 20px;
    margin-right: 18px;
    background: url("../graphics/arrow-left-grey.svg") no-repeat center center / 100% 100%;
    cursor: pointer;
}

.sub-header h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    line-height: 100%;
    font-weight: 300;
    color: #646464;
    margin: 0;
    padding-left: 0;
}

#narrow .sub-header h1 {
    font-size: 24px;
    line-height: 120%;
    font-weight: 500;
}

.sub-header .sub {
    font-weight: 500;
    margin-top: 6px;
    line-height: 100%;
}

.sub-header .msg-btn {
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-left: 24px;
    position: relative;
    top: 2px;
    background: url("../graphics/message-grey.svg") no-repeat center center / 100% 100%;
    cursor: pointer;
}

.sub-header .prof-btn {
    width: 18px;
    height: 18px;
    margin-left: 24px;
    position: relative;
    top: 2px;
    background: url("../graphics/profile-icon.svg") no-repeat center center / 100% 100%;
    cursor: pointer;
}

/*.sub-header .edit-btn {
    width: 16px;
    height: 16px;
    margin: 4px 24px 0 24px;
    position: relative;
    top: 2px;
    background: url("../graphics/edit-icon.svg") no-repeat center center/100% 100%;
    cursor: pointer;
}*/

.sub-header .prof-disp-sel {
    margin: 3px 0 0 8px;
}

.sub-header .impression-icon {
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-left: 44px;
}

#narrow .sub-header .impression-icon {
    width: 20px;
    height: 20px;
    margin-left: 24px;
}

