.geo-location {
    flex: 1 1 auto;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

#narrow .geo-location .pr-sub-item {
    flex-direction: column;
    align-items: flex-start;
}

.geo-location .line-editor {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
}

.geo-location input {
    width: 20px; /* roztáhne se na maximální šířku pomocí flex */
}

.geo-location .btn-edit {
    display: flex;
    margin-top: 12px;
}

.geo-location .btn-edit:first-child {
    margin-top: 0;
}

.geo-location .btn-edit button {
    margin-right: 12px;
}

.geo-location .btn-search {
    margin-left: 12px;
}

#narrow .geo-location .btn-search {
    margin-top: 12px;
    margin-left: 0;
}


.geo-location .ld-box {
    margin-top: 12px;
}

