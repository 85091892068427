/* Zobrazen� pro velk� obrazovky: */
@media screen and (min-width: 900px) {
    .login-page {
        max-width: 1170px;
        padding: 0 32px;
        margin-left: auto;
        margin-right: auto;
    }

    /* tituln� str�nka */
    #front {
        position: relative;
        min-height: calc(100vh - 70px);
        text-align: left;
    }

    #front #girl1 {
        position: relative;
        left: 32px; /* kompenzace velikosti p�esahuj�c� bubliny - aby byla vycentrovan� vlastn� fotka*/
        max-width: 54vw;
    }

    #front #title {
        position: absolute;
        right: 0;
        top: 620px;
        padding-left: 16px;
    }

    #front .login-box {
        position: absolute;
        top: 0;
        right: 0;
    }


    /* Dal�� obsah �vodn� str�nky*/
    .two-columns {
        display: flex;
        flex-direction: row;
    }
    
    .col1 {
        padding-right: 28px;
    }
    .col2 {
        padding-left: 28px;
    }
    
    .col1, .col2 {
        width: 50%;
        flex-grow: 1;
    }

    .login-page #login2 .login-box {
        margin-left: 26px;
    }
}

/* Zobrazen� pro �zk� obrazovky */
@media screen and (max-width: 899px) {
    .login-page {
        margin: 0 12px;
    }

    #front {
        text-align: center;
    }

    #front #title div {
        text-align: left;
    }

    #front #girl1 {
        width: 100%;
    }

    #front #title {
        margin-top: 32px;
        margin-left: auto;
        margin-right: auto;
    }
}


.login-top {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
}

/* Tituln� str�nka */
#front #girl1 {
    /*max-width: 570px;*/
    margin-top: 72px;
}

#front #title {
    width: fit-content;
    font-weight: 300;
    font-size: 28px;
    line-height: 200%;
    letter-spacing: 1px;
    background-color: rgba(250, 250, 250, 0.5);
}

#front #title div {
    width: fit-content;
}

#front #title .l3 {
    font-weight: 500;
    font-size: 32px;
    color: black;
    line-height: 60px;
}

#front .login-box {
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    background-color: #fafafa;
    padding-left: 4px;
}


/* Dal�� obsah �vodn� str�nky*/


.login-page {
    margin-bottom: 120px;
    font-size: 18px;
    line-height: 200%;
}

.login-page h1 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 48px 0 32px 0;
    font-weight: 300;
    font-size: 48px;
    color: black;
    line-height: 100%;
}

.login-page ol {
    margin: 0px;
    padding-left: 18px;
}

.login-page li {
    margin-top: 16px;
    padding-left: 8px;
}

/* Druh� p�ihla�ovac� box na konci*/
.login-page #login2 h1 {
    margin-bottom: 18px
}

.login-page #login2 .sub {
    margin-left: 26px;
    font-weight: 500;
}

.login-page #login2 .login-box {
    margin-top: 32px;
}

.login-page #login2 .msg-box {
    height: auto;
}
