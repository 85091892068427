.prof-disp-sel {
    display: flex;
    flex: 0 0 auto;
    font-size: 14px;
    line-height: 100%;
    font-weight: 500;
    box-sizing: border-box;
}

.prof-disp-sel .selection-control {
    padding-right: 18px;
    background: url("../graphics/arrow-down-grey.svg") no-repeat right center/12px 100%;
    flex: 0 0 auto;
    /*margin-bottom: 12px;*/
}

.prof-disp-sel .wnd-item {
    font-size: 16px;
    font-weight: 300;
}