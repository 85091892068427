.search-box {
    position: absolute;
    right: 16px;
    top: 20px;
}

#narrow .search-box {
    right: 0px;
}

.search-box.open {
    position: initial;
    margin-bottom: 12px;
}

.search-box .input-box input {
    padding: 6px 12px;    
}

.search-box img {
    cursor: pointer;
}

.search-box .si {
    padding-left: 8px;
}

.search-box .di {
    padding-right: 8px;
}

