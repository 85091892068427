.date-editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}

.date-editor .day-row {
    display: flex;
    flex-direction: row;
}

.date-editor .day-row .wnd-item {
    width: 16px;
    text-align: right;
}

.date-editor .selection-box {
    margin-right: 8px;
    margin-bottom: 4px;
}