	 /* Slider */
.slider {
    display: flex;
    flex-direction: column;
}

.slider .bar-row {
    display: flex;
    flex-direction: row;
}

.slider .bar-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
}

.slider .txt {
    display: flex;
    flex-direction: row;
    line-height: 150%;
    font-weight: 300;
    font-size: 14px;
    white-space: pre-wrap;
}

#narrow .slider .txt {
    font-size: 12px;
}

.slider .txt .pre {
    text-align: center;
    margin-right: 12px;
    margin-top: 0;
    margin-bottom: auto;
}

.slider .txt .min {
    text-align: left;
    margin-right: 12px;
    margin-top: auto;
    flex: 1 1 2px;
}

.slider .txt .max {
    text-align: right;
    margin-left: 12px;
    margin-top: auto;
    flex: 1 1 1px;
}

.slider .txt .red-min {
    text-align: left;
    margin-bottom: auto;
    font-size: 12px;
    line-height: 150%;
    color: #c43000;
}

.slider .txt .mid { /* dělá rozestup uprostřed mezi červeným textem minima a maxima */
    flex: 1 1 1px;
}

.slider .txt .red-max {
    text-align: right;
    margin-bottom: auto;
    font-size: 12px;
    line-height: 150%;
    color: #c43000;
}

.slider .bar-wrap {
    cursor: pointer;
}

.slider .txt.below {
    width: calc(100% - 140px);  /* odečítá se šířka noans-group včetně levého marginu*/
}

#narrow .slider .txt.below {
    width: calc(100% - 68px);  /* odečítá se šířka noans-group včetně levého marginu*/
}

.slider .txt.below .min, .slider .txt.below .max {
    margin-top: 0;
    margin-bottom: auto;
}

.slider .txt.below .disp-row {
    flex-grow: 1;
}

.slider .bar {
    height: 2px;
    background-color: #DDDDDD;
    margin: 12px 0;
    position: relative;
}

.slider .n0 {
    width: 4px;
    height: 4px;
    background-color: #646464;
    top: -1px;
    border-radius: 2px;
    left: calc(50% - 2px);
}

.slider .n {
    width: 2px;
    height: 2px;
    background-color: #646464;
    top: 0px;
    left: 50%;
}

.slider .lred {
    height: 2px;
    width: 0;
    background: linear-gradient(to right, rgba(255,60, 0, 0.5), rgba(255,60, 0, 0)) ;
    top: 0px;
}

.slider .rred {
    height: 2px;
    width: 0;
    background: linear-gradient(to left, rgba(255,60, 0, 0.5), rgba(255,60, 0, 0)) ;
    top: 0px;
}

/* - barva lišty, pokud obsahuje čudlík: */
.slider .set .bar {
    background-color: #b5deeb;
}

.slider .set .n0 {
    background-color: #138aad;
}

.slider .set .n {
    background-color: #138aad;
}

.slider .bar span {
    display: block;
    position: absolute;
}

.slider .knob {
    width: 10px; /* pokud se změní, musí se změnit i konstanta KNOB_WIDTH v 'controls/Slider.js'*/
    height: 10px; 
    top: -4.5px;
    left: -5px;
    border-radius: 7px;
    background-color: #00C3FF;
    box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.2);
}

.slider .knob:hover {
    box-shadow: 0px 0px 0px 8px rgba(0,195,255,0.2);
}

.slider .noans-group {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-shrink: 0;
    margin-left: 40px; /* !!! pokud se upravuje šířkka nebo margin, musí se adekvátně upravit i styl .txt.below */
    width: 100px;
}

#narrow .slider .noans-group {
    margin-left: 8px; /* !!! pokud se upravuje šířkka nebo margin, musí se adekvátně upravit i styl .txt.below */
    width: 60px;
}

.slider .noans-group .txt {
    margin-top: auto;
    font-style: italic;
}

#narrow .slider .noans-group .txt {
    font-size: 12px;
    line-height: 150%;
}

.slider .noans-group .btn {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #969696;
    margin: 6px auto 7px auto;
    cursor: pointer;
}

.slider .noans-group .btn[set="1"] {
    background-color: #00C3FF;
    box-shadow: inset 0px 0px 0px 1.5px rgba(255,255,255,1);
} 
