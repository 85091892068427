.user-entry {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    font-weight: 300;
    line-height: 100%;
    flex-basis: 0;
    align-items: center;
    padding: 6px 16px 6px 8px;
    cursor: pointer;
    border-top-left-radius: 31px;
    border-bottom-left-radius: 31px;
}

#narrow .user-entry {
    padding-left: 0;
}

.user-entry:hover {
    background-color: #fafafa;    
}

.user-entry small {
    font-size: 12px;
}


.user-entry .impression-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    border-radius: 7px;
    background-color: #fafafa;
    box-shadow: 0px 0px 6px 6px rgba(250, 250, 250, 1);
}

.user-entry.selected {
    background-color: #e6f9ff;
}

.user-entry.selected .impression-icon {
    background-color: #e6f9ff;
    box-shadow: 0px 0px 6px 6px rgba(230, 249, 255, 1);
}


.user-entry .wait-indicator {
    margin-left: 12px;
}

.user-entry .txt {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1 1 auto;
}

.user-entry .name {
    font-weight: 300;
}

.user-entry.bold .name {
    color: black;
    font-weight: 500;
}

.user-entry .sub {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 100%;
    white-space: nowrap; /* zabraňuje zalamování řádků, ale na explicitním new-line řádek nezalomí */
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
}

.user-entry.bold .sub {
    color: black;
    font-weight: 500;
}

.user-entry .overlay {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

