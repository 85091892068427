#header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
/*    position: fixed;
    overflow: visible;
    top: 0;
    left: 0;*/
    z-index: 100;
    flex: 0 0 auto;
    line-height: 100%;
}

/* Pruh přes celou obrazovku se stínem: */
#header:before {
    content: " ";
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 300%;
    background-color: white;
    box-shadow: 0px 0px 39px 0px rgba(0,0,0, 0.08);
}


#header a {
    padding-bottom: 4px;
    position: relative;
    outline: none;
}

#header .nf-val {
    position: absolute;
    top: -4px;
    right: 3px;
    background-color: #ff3c00;
    color: white;
    font-size: 10px;
    font-weight: 500;
    padding: 0px 2px 1px 2.5px;
    border-radius: 6px;
    line-height: 110%;
}

#narrow #header .nf-val {
    right: -5px;
}

#header .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    padding-bottom: 8px;
    padding-right: 12px;
}

#header nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    padding-bottom: 8px;
}

#narrow #header nav {
    height: 40px;
    margin-left: 12px;
}

#header #logo {
    height: 32px;
    margin-top: 17px;
    padding-left: 8px;
}

#narrow #header #logo {
    height: 20px;
    padding-left: 12px;
}

#header .button {
    margin-top: 17px;
    padding: 0 8px;
    margin-left: 32px;
}

#narrow #header .button {
    margin-top: 0;
    padding: 0 0 2px 2px;
}


/* <a> element označení pomocí NavLink jako právě aktivní*/
#header a[aria-current] {
    border-bottom: 2px solid #ff3c00;
}

#header .first {
    margin-left: 158px;
}

#header .no-margin {
    margin-left: 0px;
}

#header #logout {
    padding-left: 48px;
    padding-right: 12px;
    vertical-align: middle;
    align-self: center;
    cursor: pointer;
    z-index: 100;
}
#narrow #header #logout {
    padding-left: 12px;
    padding-bottom: 2px;
}

/* Informace o uživateli a XP*/
#user-info {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 3px;
    align-self: center;
}

#user-info .name {
    font-weight: 500;
    line-height: 100%;
    color: black;
}

#user-info .xp {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}

#user-info .xp.single {
    margin-top: 6px;
}

/*#user-info .xp-num {
    margin-bottom: 1px;
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0;
    line-height: 100%;
    color: #969696;
}*/

#user-info .xp-lbl {
    width: 35px;
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0;
    line-height: 100%;
    color: #969696;
}

#user-info .xp-bar {
    border: 1px solid #EEE;
    height: 4px;
    width: 100px;
}

#user-info .xp .disp-row {
    margin-top: 2px;
    align-items: center;
}

#header .user-icon {
    position: relative;
    top: 4px;
    width: 38px;
    height: 38px;
    margin-right: 10px;
}

#header .user-icon .letter {
   font-size: 26px;
   padding-bottom: 0;
}
