.image-view.box {
	width: 179px;
	height: 179px;
	margin: 2px;
	justify-content: center;
    align-items: center;
}

.image-view {
	position: relative;
    display: flex;
	max-width:100%;
	max-height:100%;
}

.image-view img {
	max-width:100%;
	max-height:100%;
}

.image-view .slide {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 10000;
    display: flex;
	justify-content: center;
    align-items: center;
	background-color: black;
}