.user-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    flex: 0 0 auto;
    border-radius: 50%;
    margin-right: 12px;
}

.user-icon .letter {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 300;
    line-height: 100%;
    color: white;
    padding-bottom: 1px;
}


.user-icon .active-dot {
    position: absolute;
    left: 73%;
    top: 73%;
    width: 8px;
    height: 8px;
    background-color: #49e600;
    border: 2px solid white;
    border-radius: 6px;
}