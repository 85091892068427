.acc-control {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center center;
}

.acc-control.wr {
    cursor: pointer;
}

.acc-control.acc-private {
    background-image: url("../graphics/acc-private.svg");
}

.acc-control.acc-friend {
    background-image: url("../graphics/acc-friend.svg");
}

.acc-control.acc-acq {
    background-image: url("../graphics/acc-acq.svg");
}

.acc-control.acc-public {
    background-image: url("../graphics/acc-public.svg");
}

/* - položky menu výběru přístupových práv: */
.acc-control .wnd-item {
    padding-left: 28px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 5px center;
}

.acc-control .wnd-item[value="0"] {
    background-image: url("../graphics/acc-private.svg");
}

.acc-control .wnd-item[value="65536"] {
    background-image: url("../graphics/acc-friend.svg");
}

.acc-control .wnd-item[value="131072"] {
    background-image: url("../graphics/acc-acq.svg");
}

.acc-control .wnd-item[value="196608"] {
    background-image: url("../graphics/acc-public.svg");
}

.acc-control .wnd-item[value="delete"] {
    background-image: url("../graphics/delete-icon.svg");
}
