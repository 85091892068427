.image-picker {
	box-sizing: border-box;
	width: 150px;
	height: 150px;
	margin: 16px;
	padding: 16px;
	background-color: white;
	border: 2px dotted #CCC;
	color: #AAA;
	font-size: 14px;
	line-height: 180%;
	font-weight: 300;
	text-align: center;
    display: flex;
    align-items: center;
	cursor: pointer;
}

.image-picker .plus {
	font-size: 40px;
}

.image-picker .small {
	font-size: 12px;
	line-height: 180%;
}

.image-picker:hover, .image-picker.drag {
	border: 2px solid #888;
	color: #888;
}
