.tree-item {
    line-height: 100%;
}

.tree-item .title {
    padding: 8px 8px;
    line-height: 100%;
}

#narrow .tree-item .title {
    padding: 8px 0;
}

.tree-item .title.active {
    background-color: #e6f9ff;
}

.tree-item .subtree .title {
    padding-left: 24px;
}

.tree-item .img {
    display: inline-block;
    width: 14px;
    height: 12px;
    background: url("../graphics/arrow-down-black.svg") no-repeat left 4px/10px;
    cursor: pointer;
}

.tree-item.collapsed .img {
    background: url("../graphics/arrow-right-black.svg") no-repeat 2px 2px/auto 10px;
}

.tree-item .nf-val {
    font-weight: 500;
    color: #ff3c00;
}
.tree-item .nf-val:before {
    font-weight: 500;
    color: #ff3c00;
    content: " (";
}
.tree-item .nf-val:after {
    font-weight: 500;
    color: #ff3c00;
    content: ")";
}

.tree-item a {
    font-size: 16px;
    line-height: 100%;
    color: black;
    text-decoration: none;
}

.tree-item a:focus {
    outline: none;
}

.tree-item .subtree {
    margin-bottom: 12px;
}

.tree-item .subtree a {
    font-size: 16px;
}*/

.tree-group a {
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    color: black;
}
