.message-box {
    position: relative;
	background-color: white;
	padding: 32px;
	max-height: 95vh;
	width: 600px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

.message-box .content {
	display: flex;
	flex-direction: row;
	align-items: center;
    font-family: 'Work Sans', sans-serif;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 300;
    line-height: 200%;
	font-style: normal;
    color: black;
}

.message-box .btn-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 32px;
}

.message-box .btn-row button {
	margin: 0 16px;
}

.message-box.error .msg-icon {
	flex: 0 0 auto;
	width: 64px;
	height: 64px;
	margin-right: 32px;
    background: url("../graphics/error-icon.svg") no-repeat center center/100% 100%;
}

.message-box.query .msg-icon {
	position: relative;
	width: 64px;
	height: 64px;
	margin-right: 32px;
	background-color: #ff3c00;
	border-radius: 32px;
}

.message-box.query .msg-icon:before {
	position: absolute;
	left: 14px;
	top: 14px;
	font-size: 64px;
	font-weight: 500;
	content: "?";
	color: white;
}