.profile-editor .back-btn {
    display: inline-block;
    position: relative;
    top: 2px;
    width: 24px;
    height: 24px;
    margin-right: 18px;
    background: url("../graphics/arrow-left-grey.svg") no-repeat center center / 100% 100%;
    cursor: pointer;
}
