.icon {
    display: inline-block;
	position: relative;
	top: 3px;
	width: 16px;
	height: 16px;
	background: no-repeat center center / 100% 100%;
	cursor: pointer;
}

.icon.profile {
	background: url('./graphics/profile-icon.svg') no-repeat center center / 100% 100%;
}

.icon.chat {
	background: url('./graphics/chat-icon.svg') no-repeat center center / 100% 100%;
}

.icon.odate {
	background: url('./graphics/odate-icon.svg') no-repeat center center / 100% 100%;
}

.icon.impress {
	background: url('./graphics/impression.svg') no-repeat center center / 100% 100%;
	cursor: default;
}

#fajn-lidi {
	float: left;
	width: 330px;
	max-width: 100%;
	position: relative;
	top: 10px;
	margin: 26px 30px 24px 4px;
}

