.loading-page {
    line-height: 100%;
    font-size: 24px;
    margin: auto;
    text-align: center;
}

.loading-page .wait-indicator {
    width: 64px;
    height: 64px;
    margin: auto;
    margin-left: auto;
}

.loading-page .msg {
    margin: 32px 0;
}

.loading-page .fatal-error {
    color: #ff3c00;
    font-size: 24px;
    line-height: 200%;
}

.loading-page button {
    font-size: 30px;
    line-height: 150%;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 8px 12px;
    border: 2px solid black;
    margin-bottom: 24px;
    color: #646464;
    background: white;
}

.loading-page button:hover {
    background-color: #FF9D7F;
    color: black;
}
