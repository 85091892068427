.wait-indicator {
    line-height: 100%;
    display: flex;
    align-items: center;
}

.wait-indicator img {
    width: 100%;
    height: 100%;
}
