@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,500;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');

/* Zobrazení pro velké obrazovky: */
@media screen and (min-width: 900px) {

    .layout-top {
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;    
    }

}

/* Zobrazení pro úzké obrazovky */
@media screen and (max-width: 899px) {

    .layout-top {
        width: 100vw;
    }

    /* Mobily */
/*    @media (hover: none) {
        body {
            overflow: hidden;
        }
    }*/
}

html {
    scroll-behavior: smooth;
}

body {
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    font-family: 'Work Sans', sans-serif;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 300;
    line-height: 200%;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

table {
    margin: 0;
    padding: 0;
    border-spacing: 0;
    border: none;
}

td {
    vertical-align: top;
}

b, .bold {
    font-weight: 500;
}

strong {
    color: #ff5e2d;
    font-weight: 500;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: black;
    line-height: 150%;
    padding: 0;
    margin: 24px 0 18px 0;
}

#narrow h1 {
    font-size: 28px;
}

.unindented h1 {
    padding: 0 32px;
}

#narrow .unindented h1 {
    padding: 0 12px;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
    color: #ff3c00;
    margin: 16px 0;
}

p {
    margin: 12px 0;
}

.unindented p {
    padding: 0 32px;
}

#narrow .unindented p {
    padding: 0 12px;
}

ol {
    padding-left: 20px;
    margin-bottom: 32px;
}

li {
    padding-left: 8px;
    margin: 16px 0;
}

.italic {
    font-style: italic;
}

.align-right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-left: auto;
}

a {
    color: inherit;
}

a.big {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
    text-decoration: none;
    color: black;
    padding-left: 18px;
    background: url("graphics/triangle-right-black.svg") no-repeat left center/7.5px 12px;
}

a.big:hover {
    text-decoration: underline;
}

.error {
    color: #ff3c00;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 6px;
}

.no-send-err {
    color: #ff3c00;
    font-weight: 500;
    font-style: italic;
    margin: 8px;
    padding: 4px 12px;
    border: 2px solid #ff3c00;
}

button {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
	line-height: 100%;
    letter-spacing: 1px;
    padding: 8px 16px;
    border: 1px solid black;
    background-color: #f0f0f0;
    color: black;
}

button:hover {
    background-color: #FF9D7F;
}

button.black {
	margin-right: 16px;
	color: white;
    border-color: #646464;
	background-color: #646464;;
}

button.black:hover {
    background-color: #7F1E00;
}

button.small {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 3px 6px;
    border: 1px solid black;
    background-color: white;
    color: black;
}

button.small:hover {
    background-color: #FF9D7F;
}


/* Box s rotujícím kolečkem vlevo - indikátor nahrávání */
.ld-box {
    font-style: italic;
    padding-left: 26px;
    height: 20px;
    vertical-align: middle;
    background: url("graphics/loading.gif") no-repeat left center/20px 20px;
}

/* paceholder text v Selectionu*/
.placeholder {
    color: #bbb;
    font-weight: 300;
    font-style: italic;
}

.disp-row {
    display: flex;
    flex-direction: row;
}

.disp-col {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.input-box {
    border-bottom: 2px solid #646464;
    box-shadow: 0px 0px 21px 7px rgba(0,0,0,0.04);
    box-sizing: border-box;
    display: flex;
    background-color: white;
}

.input-box input {
    color: #646464;
    background-color: white;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 12px 12px;
    border: none;
    flex: 1 1 auto;
}

.input-box input::placeholder {
    color: #999;
    font-weight: 300;
}

.input-box input:focus {
    outline: none;
}

/* override barvy vnitřku (převážně pro cbox) */
.bg-orange {
    background-color: #fff8f5;
    box-shadow: inset 0px 0px 30px 5px rgba(255, 60, 0, 0.07);
}

/* box s obsahem zobrazovaný převážně na domovské stránce */
.cbox {
    margin: 12px 32px 12px 0;
    background-color: white;
    position: relative;
    width: 100%;
}

.cbox:first-child {
    margin-top: 0;
}

/* aby mohl mít box zároveň vnitřní a vnější stín, vnější vykreslíme do pseudo-elementu:*/
.cbox:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    box-shadow: 0px 0px 8px 1px rgba(0,0,0, 0.05);
}

/* vnitřní box cboxu. Okraj má jako margin, takže se skládá s marginem vnitřku. */
.cbox-inner {
    margin: 24px;
}


/* Modální okna (vždy uprostřed obrazovky, zbytek překryje poloprůhlednou vrstvou: */
.modal-window {
	position: fixed;
	z-index: 1000;
	/*Vycentrovat:*/
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 21px 7px rgba(0,0,0,0.5);
}

#narrow .modal-window {
	top: 0;
	left: 0;
    right: 0;
    bottom: 0;
	transform: initial;
    margin: auto;
    overflow-y: auto;
}

.modal-window:before { /* bílá poloprůhledná vrstva přes celou obrazovku */
    content: "";
	position: fixed;
	z-index: -1;
	width: 200vw;
	height: 200vh;
	left: -100vw;
	top: -100vh;
	background-color: rgba(255,255,255, 0.7);
}



/* Vlastní aplikace */
/* - vlastní obsah stránky */
.layout-top { /* celá stránka včetně headeru*/
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#layout-main { /* celá stránka mimo header */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1 1 auto;
    overflow: hidden;
    box-shadow: -40px 0px 30px 10px white; /* plynulý přechod mezi bílou a šedou doleva*/
}

/* Tree pane*/
#layout-tree {
    width: 320px;
    flex: 0 0 auto;
    background-color: white;
    border-right: 1px solid #EEE;
    overflow-y: hidden; /* skrýt scroll-bar*/
    scrollbar-width: thin;
}

#layout-tree:hover {
    overflow-y: auto; /* na hover zobrazit scroll-bar, pokud je potřeba */
}

#layout-tree h1 {
    font-family: 'Montserrat', sans-serif;
    padding: 0 8px;
    font-size: 24px;
    line-height: 120%;
    font-weight: 500;
    color: #ff3c00;
    margin-top: 22px;
    margin-bottom: 22px;
}

/* Hlavní panel */
#layout-content-main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: stretch;
    overflow: hidden;
}

#nf-area {
    display: flex;
    padding: 0px 6px 6px 24px;
    margin-top: 12px;
}

#layout-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: auto; /* pevná velikost na výšku stránky */
    scrollbar-width: thin;
}

/* Obecný vlastní obsah stránky - dělá okraje a scroll-bar */
.page-content {
    padding: 0 32px 32px 32px;
    flex-grow: 1;
}

#narrow .page-content {
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    width: 100%;
}

.page-content.unindented, #narrow .page-content.unindented{
    padding-left: 0;
    padding-right: 0;
}

#page-content:hover {
    overflow-y: auto; /* na hover zobrazit scroll-bar, pokud je potřeba */
}

.page-content .desc {
    margin-top: 24px;
    margin-bottom: 12px;
}

.page-content h1+.desc {
    margin-top: 0px;
    margin-bottom: 24px;
}


/* ---------- Skupina zpráv (v MessagePane a UnreadMessages) */
.message-group {
    display: flex;
    flex-direction: row;
    margin: 6px 0px;
}

.message-group .msgs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 66%;
}

.message-group .msg {
    position: relative; /* kvůli absolutnímu pozicování .wait-indicator*/
    max-width: 400px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 180%;
    background-color: white;  
    padding: 12px 18px;
    margin: 3px 0px;
    border-radius: 12px;
    position: relative;
}

.message-group .msg.unread-in {
    font-weight: 500;
}

/* aby se stíny nepřekreslovaly přes následující boxy, udělat pseudo-elementy se stejnou velikostí a stín dát jim:*/
.message-group .msg:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 12px;
    box-shadow: 0px 0px 21px 7px rgba(0, 0, 0, 0.04);
}

.message-group.out .user-icon {
    margin-right: 0px;
    margin-left: 12px;
    order: 1;
}

.message-group.out .msgs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
/*    text-align: right;*/
    margin-left: auto;
}

.message-group.out .msg {
    background-color: #f2fcff;
    color: #4a4a4a; /* na trochu tmavší pozadí trochu tmavší text*/
    box-shadow: inset 0px 0px 30px 5px rgba(0, 139, 181, 0.07);
}

.message-group.out .msg:before {
    box-shadow: none;
}

/* ---------- Chat ------- */
#chat-main {
    position: relative;  /* kvůli absolutnímu pozicování .wait-indicator a .impression-icon*/
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}

#chat-main .wait-indicator {
    position: absolute;
    left: 24px;
    top: 20px;
    width: 24px;
    height: 24px;
}

.message-block {
    display: flex;
    flex-grow: 1;
    padding: 12px 6px 0 24px;
    flex-direction: column;
    vertical-align: bottom;
}

/*FIXME toto je hack kvůli chybě ve Firefoxu. Pokud je panel zpráv chatu odscrollovaný na konec,
  když se začne psát do odesílacího políčka (a některých dalších příležitostech), Firefox
  vygeneruje scroll nahoru o 21 pixelů. Proto pro Firefox přidáme nakonec 21px, aby zprávy
  nebyly uříznuté na konci.
  ***AŽ TO FIREFOX OPRAVÍ, MŮŽE SE NÁSLEDUJÍCÍ PRAVIDLO ODSTRANIT*** 
*/
@supports (-moz-appearance:none) { /* Deklarace platná pouze pro Firefox */
    .message-block {
        padding-bottom: 21px;
    }
}

/* - progress indikátor odesílání: */
#chat-main .message-block .msg .wait-indicator {
    position: absolute;
    left: unset;
    right: 4px;
    top: 4px;
    margin: 0;
    width: 12px;
    height: 12px;
}

#chat-main .message-block .message-group.out .msg .wait-indicator {
    right: unset;
    left: 4px;
}

/* - obálka pro zprávu s anotaci: */
.message-block .msg-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4px;
}

.message-block .msg-wrap .message-group.out {
    align-items: flex-end;
}

.message-block .msg-wrap.err {
    cursor: pointer;
}

.message-block .message-group.out .msg-wrap {
    align-items: flex-end;
}

/* - anotace chyby odesílální zprávy: */
.message-block .msg-wrap .note {
    font-size: 12px;
    line-height: 100%;
    font-weight: 500;
}

/* - anotace chyby odesílální zprávy: */
.message-block .msg-wrap .note.err {
    color: #ff3c00;
}

/* - anotace přečtení zprávy: */
.message-block .msg-wrap .note.rd {
    font-style: italic;
}


/* - mezititulky: */
.message-block .brk {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    text-transform: capitalize; /* Aby se "dnes" a "včera" převedlo první velké písmenu */
}
.message-block .brk.unread {
    display: flex;
    text-transform: none;
    color: #00a0d1;
    text-align: left;
    /*margin-left: 62px;*/
    font-size: 14px;
}

.message-block .brk.unread:before, .message-block .brk.unread:after {
    color: black;
    content:'';
    flex-grow: 1;
    border-bottom: #00a0d1 solid 1.5px;
    margin: 7px 0.5em auto 0.5em;
}

.message-block .brk.unread:before {
    flex-grow: 0;
    width: 50px;
}
/* ---------- Profil ------- */

.mandatory {
    color: #ff3c00;
}

.acc-private-bg {
    background-color: #f2fcff;
}

.acc-acq-bg {
    background-color: #fff8f5;
}

.acc-friend-bg {
    background-color: #fff3f0;
}

/*.acc-public-bg {
    background-color: white;
} */


.pr-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e8e8e8;
    padding: 24px 24px 24px 32px;
    position: relative; /* vůči němu se umisťuje AccControl */
}

#narrow .pr-item {
    padding: 32px 12px 24px 12px;
}

.pr-item .pr-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 150%;
}

#narrow .pr-item .pr-inner {
    font-size: 14px;
    line-height: 150%;
}

.pr-item .nf-val {
    font-weight: 500;
    color: #ff3c00;
}
.pr-item .nf-val:before {
    font-weight: 500;
    color: #ff3c00;
    content: " (";
}
.pr-item .nf-val:after {
    font-weight: 500;
    color: #ff3c00;
    content: ")";
}

.pr-item .label {
    font-weight: 500;
    color: black;
    /*width: 300px;*/
    width: 40%;
    padding-right: 8px;
    flex: 0 0 auto;
}

.pr-item .label.wide {
    width: initial;
}

.pr-item .profile-text-item .label {
    margin-bottom: 4px;
}

.pr-item .sub {
    font-size: 14px;
    line-height: 150%;
    margin: 12px 0 0 24px;
}

#narrow .pr-item .sub {
    font-size: 12px;
    line-height: 150%;
}

.pr-item .input-box {
    flex: 1 1 auto;
}

.pr-item .input-box input {
    padding-top: 6px;
    padding-bottom: 6px;
}

.pr-item .input-box[type="int"] {
    flex-grow: 0;
}
.pr-item .input-box[type="int"] input {
    width: 50px;
}

.pr-item button {
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    padding: 6px 6px;
    border: 2px solid #646464;
    color: #646464;
    background: white;
    margin-left: 8px;
}

.pr-item button:hover {
    background-color: #FF9D7F;
    color: black;
}

.pr-sub-item {
    position: relative; /* vůči němu se umisťuje AccControl */
    display: flex;
    flex-direction: row;
    padding: 6px 24px 6px 8px;
}

.pr-q-item {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 24px 32px;
    position: relative; /* vůči němu se umisťuje AccControl */
}

#narrow .pr-q-item {
    padding: 24px 12px;
}

/* ============ Controls =====================*/

/* LineEditor */
.line-editor {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.line-editor .error {
    margin-top: 12px;
}

/* RadioButtons */
.rb-item {
    display: flex;
    flex-direction: row;
    line-height: 100%;
    margin-bottom: 8px;
}

.rb-item input {
    -moz-appearance: none;
    -webkit-appearance: none;    
    outline: none;
    width: 12px;
    height: 12px;
    border-radius: 7px;
    background-color: white;
    border: 1px solid #969696;
    margin: auto 6px auto 0;
}

.rb-item input[mark="1"] {
    background-color: #00C3FF;
    box-shadow: inset 0px 0px 0px 1.5px rgba(255,255,255,1);
} 

.rb-item label {
    margin-top: auto;
    margin-bottom: auto;
}
/* Selection */
.selection-box {
    box-shadow: 0px 0px 21px 7px rgba(0,0,0,0.04);
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    background-color: white;
    flex: 0 auto;
}

.selection-box .selection-control {
    padding-right: 18px;
    background: url("graphics/arrow-down-grey.svg") no-repeat right center/12px 7.4px;
    background-color: white;
    min-width: 80px;
    flex: 1 1 auto;
}

/* PopupWindow*/
.wnd-container {
    position: relative;
    display: inline-block;
}

.popup-window {
    padding: 12px 16px;
    position: fixed;
    z-index: 100;
    background-color: white;
    box-shadow: 0px 0px 21px 7px rgba(0,0,0, 0.1);
    overflow: auto;
}

.popup-window .wnd-item {
    cursor: pointer;    
    padding: 8px 12px;
    line-height: 150%;
}

.popup-window .wnd-item:hover {
    background-color: #abebff;
}

/* - pro víceřádkové položky okna: první (hlavní) řádek */
.popup-window .wnd-item .first {
    font-weight: 300;
}

/* - pro víceřádkové položky okna: druhý (doplňující) řádek */
.popup-window .wnd-item .second {
    font-size: 12px;
}


.sel-item {
    background-color: #abebff;
}

/* DropdownMenu*/
.dropdown-menu {
    display: flex;
    align-self: center;
    padding: 0 10px;
}

.dropdown-menu .dm-icon {
    background: url("graphics/3dots-grey.svg") no-repeat center center/100% 100%;
    cursor: pointer;
}

.dropdown-menu:hover .dm-icon {
    background: url("graphics/3dots-black.svg") no-repeat center center/100% 100%;
}

/* Switch */
.toggle-button {
    height: 10px;
    width: 24px;
    margin: 2px;
    border-radius: 5px;
    background-color: #969696;
}

.toggle-button .knob {
    width: 12px;
    height: 12px;
    border-radius: 7px;
    display: block;
    position: relative;
    top: -2px;
    left: -2px;
    background-color: white;
    border: 1px solid #969696;
}

.toggle-button[value="1"] {
    background-color: #00a0d1;
}

.toggle-button[value="1"] .knob {
    left: 12px;
    background-color: white;
    border: 1px solid #4da6c4;
}


/* ProfileTextEditor*/
.profile-text-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

#version-info {
    position: fixed;
    left: 4px;
    bottom: 4px;
    font-size: 8px;
    line-height: 100%;
    color: #eee;
}

#version-info:hover {
    color: black;
}