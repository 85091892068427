.disabled-page {
    line-height: 150%;
    font-size: 24px;
    margin: auto;
    text-align: center;
}

.disabled-page button {
    font-size: 30px;
    line-height: 150%;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 8px 12px;
    margin: 32px 16px;
    border: 2px solid black;
    margin-bottom: 24px;
    color: #646464;
    background: white;
}

.disabled-page button:hover {
    background-color: #FF9D7F;
    color: black;
}
