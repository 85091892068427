.school-editor {
    flex: 1 1 auto;
    line-height: 150%;
}

.school-editor td {
    vertical-align: middle;
    padding-bottom: 6px;
}

.school-editor .sch-name .l2{
    font-size: 12px;
}

.school-editor .school-edit {
    cursor: pointer;
    margin-top: 6px;
}

.school-editor .sch-del {
    width: 20px;
}

@media (hover: hover) {
    .school-editor .school-item:hover .sch-del {
        background: url("../graphics/delete-icon.svg") no-repeat right 6px/12px 12px;
        cursor: pointer;
    }
}

@media (hover: none) {
    .school-editor .school-item .sch-del {
        background: url("../graphics/delete-icon.svg") no-repeat right 6px/12px 12px;
        cursor: pointer;
    }
}

.school-editor .error {
    margin-top: 12px;
}

.school-editor .wait-indicator {
    margin-top: 12px;
}

