#layout-tree .profile-tree h1 {
	margin-bottom: 12px;
}

.profile-tree h1 {
    position: relative;
}

.profile-tree .clickable {
    cursor: pointer;
}

.profile-tree .edit-icon {
    position: absolute;
    right: 16px;
    top: 6px;
    width: 16px;
    height: 16px;
    background: url("../graphics/edit-icon.svg") no-repeat center center/100% 100%;
    cursor: pointer;
}

.profile-tree .prof-disp-sel {
    padding-left: 8px;
    margin-bottom: 16px;
}

