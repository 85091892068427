.lang-list td {
    vertical-align: middle;
    padding-bottom: 6px;
}

.lang-list .lang-name {
    padding-right: 12px;
}

.lang-list .lang-level {
    display: flex;
    flex-direction: row;
}

.lang-list .lang-del {
    width: 20px;
}

.lang-list .lang-item:hover .lang-del {
    background: url("../graphics/delete-icon.svg") no-repeat right center/12px 12px;
    cursor: pointer;
}

.lang-list .lang-add {
    cursor: pointer;
    margin-top: 6px;
}

.lang-list #all-lang {
    font-style: italic;
}

.lang-list .selection-box {
    flex-grow: 1;
}