/* - SendRow: */
.send-row {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    margin: 12px 24px;
    border-bottom: 2px solid black;
    background-color: white;
    box-shadow: 0px 0px 21px 7px rgba(0,0,0,0.04);
}

#narrow .send-row {
    margin: 12px 8px 4px 8px;
}

.send-row .text-editor {
    flex: 1 1 auto;
    display: flex;
}


.send-row .text-editor .input-box {
    flex: 1 1 auto;
    display: flex;
    box-shadow: unset;
    border-bottom: none;
}

.send-row #send-btn {
    flex: 0 0 auto;
    width: 28px;
    margin: 0 12px;
    background: url("../graphics/send.svg") no-repeat left center/28px 18px;
}

.send-row #send-btn:hover {
    background: url("../graphics/send-hover.svg") no-repeat left center/28px 18px;
}
