.bottom-nav {
	display: flex;
	flex-direction: row;
	padding: 32px 40px 24px 32px;
}

#narrow .bottom-nav {
	padding-left: 12px;
	padding-right: 12px;
}

.bottom-nav .left {
	display: flex;
	flex-direction: column;
}

.bottom-nav .right {
	display: flex;
	flex-direction: column;
	margin-left: auto;
}

.bottom-nav a {
    text-decoration: none;
	font-size: 14px;
	font-weight: 500;
	color: #646464;
	line-height: 150%;
}

.bottom-nav a:hover {
    text-decoration: underline;
	color: black;
}
