.image-editor {
	width: 179px;
	height: 179px;
	margin: 2px;
    display: flex;
	justify-content: center;
    align-items: center;
}

.image-editor .acc-control {
	position: absolute;	
	right: 8px;
	top: 8px;
	background-color: white;
	padding: 4px;
	border-radius: 10px;
}