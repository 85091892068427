.impression-editor {
    position: relative;
	background-color: white;
	padding: 32px;
	max-height: 95vh;
	width: 800px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

#narrow .impression-editor {
	padding: 12px 0;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	max-height: 100%;
	max-width: 100%;
}

.impression-editor .narrow-scroll {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden;
}

#narrow .impression-editor .narrow-scroll {
	overflow-y: auto;
}

.impression-editor .close-button {
    position: absolute;
	width: 16px;
	height: 16px;
	top: 12px;
	right: 12px;
    background: url("../graphics/close-icon.svg") no-repeat center center/100% 100%;
	cursor: pointer;
}

#narrow .impression-editor .close-button {
	display: none;
}

.impression-editor h1 {
    font-family: Montserrat, sans-serif;
	font-weight: 400;
	font-size: 24px;
	line-height: 125%;
	margin: 20px 0 24px 0;
	padding-left: 34px;
    background: url("../graphics/impression-hover.svg") no-repeat left 4px/24px 24px;
	position: relative;
	left: -10px;
}

#narrow .impression-editor h1 {
	margin: 0 12px 12px 24px;
	font-size: 24px;
}

.impression-editor .desc {
	padding-left: 24px;
	margin-bottom: 24px;
	font-weight: 500;
	color: #646464;
	font-size: 14px;
	line-height: 150%;
}

#narrow .impression-editor .desc {
	padding: 0 12px;
	margin-bottom: 12px;
	margin-top: 12px;
}

.impression-editor .error {
	padding-left: 24px;
	margin-bottom: 24px;
}

#narrow .impression-editor .error {
	padding: 0 12px;
	order: 1; /* v �zk�m zobrazen� d�t error t�sn� nad tla��tka, jinak nemus� b�t vid�t*/
}

.impression-editor .wait-indicator {
	padding-left: 24px;
	margin-bottom: 24px;
	font-style: italic;
}

#narrow .impression-editor .wait-indicator {
	order: 1; /* v �zk�m zobrazen� d�t wait-indicator t�sn� nad tla��tka, jinak nemus� b�t vid�t*/
}

.impression-editor .sel {
	font-size: 18px;
	font-weight: 300;
	line-height: 100%;
	padding-bottom: 4px;
	cursor: pointer;
}

.impression-editor .sel:first-child {
	margin-left: 24px;
	margin-right: 24px;
}

#narrow .impression-editor .sel:first-child {
	margin-left: 12px;
	margin-right: 18px;
}

.impression-editor .sel.active {
	color: black;
	font-weight: 500;
	border-bottom: 2px solid #ff3c00;
}

.impression-editor .tab {
	position: relative;
	margin: 24px 0;
	overflow-y: scroll;
	flex-shrink: 1;
}

#narrow .impression-editor .tab {
	overflow-y: initial;
	position: relative;
	margin: 12px 0;
	flex-shrink: 0;
}

.impression-editor .btn-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

#narrow .impression-editor .btn-row {
	padding: 0 12px 12px 12px;
	order: 1; /* v �zk�m zobrazen� d�t tla��tka do stejn� skupiny jako error a wait-indicator*/
}