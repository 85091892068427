.chat-mini-toolbar {
	position: relative;
	top: -10px;
	display: flex;
	flex-direction: row;
	font-size: 14px;
	font-weight: 500;
	line-height: 120%;
	color: #646464;
	text-transform: uppercase;
	margin-bottom: 22px;
}

.chat-mini-toolbar .profile {
	padding-left: 24px;
	margin-left: 8px;
    background: url("../graphics/profile-icon.svg") no-repeat left center/16px 16px;
	cursor: pointer;
}

.chat-mini-toolbar .impress {
	padding-left: 24px;
	margin-left: 16px;
    background: url("../graphics/impression.svg") no-repeat left center/16px 16px;
	cursor: pointer;
}

.chat-mini-toolbar .impress:hover {
    background-image: url("../graphics/impression-hover.svg");
}